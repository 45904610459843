<template>
    <section class="home-view">


        <!-- Hero banner -->
        <section class="hero-banner">
            <div class="hero-content">
                <div class="inner-wrapper">
                    <header class="hero-header">
                        <h2 class="super-title">Publications</h2>
                        <h1 class="title">
                            Towards a fair and sustainable Europe 2050
                        </h1>
                        <p class="subtitle">
                            Social and economic choices in sustainability
                            transitions
                        </p>
                    </header>

                    <article class="hero-info">
                        <div class="info-section">
                            <h3 class="section-title">Authors</h3>
                            <p class="authors">
                                <span>Cristian Matti</span>
                                <span>Kathrine Jensen</span>
                                <span>Laurent Bontoux</span>
                                <span>Petra Goran</span>
                                <span>Alberto Pistocchi</span>
                                <span>Maurizio Salvi</span>
                            </p>
                        </div>
                        <div class="info-section">
                            <h3 class="section-title">Layout and graphics</h3>
                            <p class="layout-graphics">
                                <span>Emporij d.o.o.</span>
                                <span>Alessandro Borsello</span>
                            </p>
                        </div>

                        <div class="social-share">
                            <p class="share-text">Share publication</p>
                            <div class="share-buttons">
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/link-alt.svg"
                                        alt="Share on Link"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/LinkedIn.svg"
                                        alt="Share on LinkedIn"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/twitter.svg"
                                        alt="Share on X"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/facebook.svg"
                                        alt="Share on Facebook"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                            </div>
                        </div>
                    </article>

                    <nav class="hero-links">
                        <ul>
                            <li>
                                <a href="#" class="link-item">
                                    <img
                                        src="/assets/svg/plus.svg"
                                        alt="Add to my publications"
                                        width="24"
                                        height="24"
                                    />
                                    Add to my publications</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/bell-plus.svg"
                                        alt="Create alert"
                                        width="24"
                                        height="24"
                                    />Create alert</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/link.svg"
                                        alt="Permanent link"
                                        width="24"
                                        height="24"
                                    />Permanent link</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/purchase-tag-alt.svg"
                                        alt="Metadata RDF"
                                        width="24"
                                        height="24"
                                    />Metadata RDF</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/code.svg"
                                        alt="Embed"
                                        width="24"
                                        height="24"
                                    />Embed</a
                                >
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <figure class="hero-image">
                <img
                    src="/assets/images/example_1.jpg"
                    role="presentation"
                    alt=""
                    width="654"
                    height="654"
                    loading="lazy"
                />
            </figure>
        </section>

        <!-- Contents -->
        <div class="contents">


            <!-- Text block -->
            <section class="text-block" id="abstract">
                <header class="section-header">
                    <h2 class="subtitle-6">Abstract</h2>
                </header>
                <article class="section-content subtitle-7">
                    <p>This foresight study explores possible and necessary changes in the European social and economic systems as the European Union engages in managing sustainability transitions towards 2050.</p>
                    <p>With this focus, the study presents strategic areas of intervention covering a new social contract, governance for sustainability, people and economy, and the global perspective on sustainability.</p>
                    <p>The study reflects on the agency of EU actors (such as government at various levels, business, and communities) to address the strategic areas of intervention as part of collectively addressing sustainability transitions.</p>
                    <p>The study builds on a participatory foresight exercise, which generated four foresight scenarios for a climate-neutral EU in 2050. Based on each scenario, a corresponding transition pathway was co-created and analysed through the process.</p>
                    <p>The study presents and analyses these outputs of the process. The outputs can also serve as input to policymakers and practitioners interested in conducting new participatory exercises on sustainability transitions.</p>
                </article>
            </section>


            <!-- Text block -->
            <section class="text-block" id="acknowledgments">
                <header class="section-header">
                    <h2 class="subtitle-6">Acknowledgments</h2>
                </header>
                <article class="section-content subtitle-7">
                    <p>This study would not have been possible without the expertise and insight of the many contributors involved along the way. Their input and reflections guided the development of the content of this study from start to finish.</p>
                    <p>The authors are indebted to the workshop participants for dedicating their time and knowledge to this project and enabling the cocreation that this study draws on. We express our sincere gratitude to all the participants.</p>
                    <p>The study was conducted as the fourth foresight exercise in the context of the European Commission’s annual strategic foresight reports. The preceding strategic foresight reports and underpinning JRC foresight studies form a foundation that this study builds on to examine the many aspects of the possible future for the EU and its citizens.</p>
                    <p>We would also like to give special thanks to the many experts from the Joint Research Centre for their support and feedback throughout the process. Their contributions and counsel helped to ground many of the discussions and deepen the content of this study. In addition, we are particularly grateful for the advice of the peer reviewers of this study.</p>
                    <p>Sincere thanks are also due to the Foresight Team of the Secretariat-General, Clementine Agosta, Grzegorz Drozd, Cornelius Hermelink, Beata Kolecka and Tanja Zegers. We are truly grateful for the close collaboration and valuable suggestions throughout the process. For sharing their advice and knowledge, we also express many thanks to the members of the European Commission’s Strategic Foresight Network, as well as several colleagues across the Commission services.</p>
                    <p>We thank our colleagues Anne-Katrin Bock, Valerie Enjolras, and Thomas Hemmelgarn for their guidance and support to this project. In addition, we would like to thank Teodora Garbovan for her instrumental contributions in the communication of the results of this study, as well as Alessandro Borsello for the graphic design and layout. Finally, the authors express our deep appreciation for the help and support of Sara Armas del Rio and Maya Lamanna in the organisation of this project.</p>
                </article>
            </section>


            <!-- Text block -->
            <section class="text-block less-margin" id="executive-summary">
                <h2 class="section-title">Executive Summary</h2>
                <div class="summary-content">
                    <p class="summary-paragraph">
                        Amidst the pressing challenges posed by the climate crisis and related extreme weather events, environmental degradation, as well as biodiversity loss, it becomes increasingly evident that swift and extensive action is imperative worldwide.
                    </p>
                    <p class="summary-paragraph">
                        <strong>As a leading force in the fight against climate change, the European Union is resolute in its commitment to achieve climate neutrality by 2050.</strong>
                    </p>
                    <p class="summary-paragraph">
                        Expanding on the previous year’s foresight analysis, which outlined 14 requirements for successful “twin” digital and green transitions, this study provided the foresight input to the European Commission’s 2023 Strategic Foresight Report by delineating key areas and measures in the social and economic spheres. It places the wellbeing of people, as well as social and economic fairness as fundamental principles within all sustainability endeavours.
                    </p>
                </div>
                <div class="summary-content">
                    <p class="summary-paragraph">
                        Faced with an epochal challenge, the European Union is taking action to steer the continent through the green transition and harness its opportunities. However, in order to become sustainable, the next years and decades require both collective action and profound changes to improve the social and economic aspects influencing people’s lives. These range from redefining wellbeing, strengthening democracy and governance to promoting new business models from a sustainability and climate neutrality perspective. The profound and sometimes radical changes described in this foresight study are part of the multiple possible ways in which the EU can collectively address sustainability transitions. What is more, they are necessary to enhance the EU’s capacity to act in a complex and constantly changing global context.
                    </p>
                </div>
                <div class="key-messages">
                    <div>
                        <p class="summary-paragraph">
                            This foresight study identifies a range of crucial social and economic changes required for sustainability transitions to become a reality in the EU in the coming decades. It presents the key messages that:
                        </p>
                        <ul class="key-messages-list">
                            <li class="key-message-item">
                                <p class="key-message-text">
                                    A just transition and a new social contract founded on sustainability are essential to strengthening the EU’s social foundations and establishing it as a strong and reliable partner on the international stage.
                                </p>
                            </li>
                            <li class="key-message-item">
                                <p class="key-message-text">
                                    Governance models need to be reformed to address sustainability challenges effectively. This entails fostering strong collaboration and defining new roles between the various levels of governance in the EU (European, national, regional, and local), with local-level governance playing a central role in both a vibrant and transparent democracy, and in climate adaptation.
                                </p>
                            </li>
                            <li class="key-message-item">
                                <p class="key-message-text">
                                    A future sustainable world starts with the reinterpretation of what wellbeing and a functional economy mean. Strategic interventions in many areas are needed to shift the common understanding of wellbeing by redefining it in terms of quality of life and fairness rather than only material wealth. A rethinking of the economy is also necessary to ensure that it delivers the necessary goods, services, and quality jobs, while functioning within planetary boundaries to be truly sustainable.
                                </p>
                            </li>
                            <li class="key-message-item">
                                <p class="key-message-text">
                                    A global perspective for the EU’s sustainability transitions is necessary to ensure their success. This entails navigating the current geopolitical changes and forging alliances with like-minded partners in the fight against climate change. The primary challenge lies in finding the optimal balance between maintaining autonomy and embracing a more effective path for multilateralism within a decolonised framework and in a shifting geopolitical landscape.
                                </p>
                            </li>
                        </ul>
                    </div>
                    
                    <figure class="summary-figure">
                        <img src="/assets/images/example_2.jpg" loading="lazy" width="367" height="920" alt="Summary Image" class="summary-image"/>
                    </figure>
                </div>
            </section>


            <!-- Highlight banner -->
            <section class="highlight-banner less-margin">
                <div class="jump-to-section">
                    <button class="icon-only" disabled>
                        <img
                            src="/assets/svg/star.svg"
                            loading="lazy"
                            width="24"
                            height="24"
                            alt="Share Icon"
                        />
                    </button>
                    <div class="jump-to-content">
                        <p class="jump-to-text">
                            <span>To learn more about the </span>
                            <span class="highlight">Strategic areas of intervention</span>
                            <span> for successful sustainability transitions</span>
                        </p>
                        <a href="#" target="_blank" class="jump-to-link button" @click.prevent="scrollTo('abstract')">
                            <span class="link-text">Jump to this section</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M9.70703 16.9496L15.414 11.2426L9.70703 5.53564L8.29303 6.94964L12.586 11.2426L8.29303 15.5356L9.70703 16.9496Z" fill="#0E47CB"/></svg>
                        </a>
                    </div>
                </div>
            </section>


            <!-- Text block -->
            <section class="text-container less-margin" id="introduction-and-framing">
                <h3>Key messages on strategic areas of intervention across the transition pathways</h3>

                <div class="message-table">
                    <div class="message-card">
                        <div class="card-header">
                            <h4 class="card-title">A new social contract</h4>
                            <p>The transformation towards sustainability requires a new social contract, calling for urgent action to address inequalities and transform the economy.</p>
                        </div>
                        <div class="card-body">
                            <ul class="card-list">
                                <li class="card-list-item">
                                    
                                    <span class="list-text">Democracies face growing discontent and inequality, while fairness, social cohesion and participation are crucial elements for adaptation and a renewal process.</span>
                                </li>
                                <li class="card-list-item">
                                    
                                    <span class="list-text">Future generations face major climatic, economic and social challenges that current policies should address, striving for intergenerational fairness as a guiding principle for a new social contract.</span>
                                </li>
                                <li class="card-list-item">
                                    
                                    <span class="list-text">Well-being is evolving into a holistic concept encompassing quality of life, health and a wide range of socio-economic and environmental factors for long-term sustainability, moving beyond material wealth.</span>
                                </li>
                                <li class="card-list-item">
                                    
                                    <span class="list-text">The provision of essential services to ensure quality of life and well-being, as well as improved working conditions, should be a policy priority to enable a just transition and promote a fair and sustainable economic development.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="message-card">
                        <div class="card-header">
                            <h4 class="card-title">Governance for sustainability</h4>
                            <p>A shift is needed in public policy towards a policy mix approach to foster systemic change.</p>
                        </div>
                        <div class="card-body">
                            <ul class="card-list">
                                <li class="card-list-item">
                                    
                                    <span class="list-text">Public finance and tax systems need to be redesigned to remain effective in the face of new pressures created by climate change, ageing and economic conditions, while tackling inequalities, targeting investment priorities and supporting the exit from unsustainable sectors.</span>
                                </li>
                                <li class="card-list-item">
                                    
                                    <span class="list-text">Agile multi-level governance mechanisms are critical for a successful transition to sustainability and to use public resources effectively while promoting change through synergies with the private sector.</span>
                                </li>
                                <li class="card-list-item">
                                    
                                    <span class="list-text">Investments that support the transition to sustainability can be promoted through a mix of innovation, environmental and sectoral policy instruments in synergy with sustainable finance and public-private sector collaboration.</span>
                                </li>
                                <li class="card-list-item">
                                    
                                    <span class="list-text">Regions and cities are crucial for transformative investments as they have the appropriate competences on industrial development and natural resource management.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="message-card">
                        <div class="card-header">
                            <h4 class="card-title">People and economy for sustainability</h4>
                            <p>People’s behaviour and their competences and skills will be critical to address the necessary changes toward sustainable lifestyles.</p>
                        </div>
                        <div class="card-body">
                            <ul class="card-list">
                                <li class="card-list-item">
                                    
                                    <span class="list-text">New circular and collaborative business models are critical to the transition to sustainable production and consumption, while current business practices require significant changes to create options to promote sustainable lifestyles.</span>
                                </li>
                                <li class="card-list-item">
                                    
                                    <span class="list-text">The transition to sustainable lifestyles is a long-term change that involves a reduction in material and energy consumption. It requires a radical change in current practices and supportive policies that lead to behavioural change while ensuring an affordable sustainable lifestyle.</span>
                                </li>
                                <li class="card-list-item">
                                    
                                    <span class="list-text">A renewed education system is needed to address the transitions to sustainability, in terms of the considerable range of competences and skills that cover lifelong learning and emerging needs, while ensuring equal opportunities in society.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="message-card">
                        <div class="card-header">
                            <h4 class="card-title">Global perspective on sustainability</h4>
                            <p>The global order of the coming decades will be complex as the number of significant players grows and global economic activities are reconfigured towards resilience.</p>
                        </div>
                        <div class="card-body">
                            <ul class="card-list">
                                <li class="card-list-item">
                                    
                                    <span class="list-text">Industry will adapt to the reorganisation of global value chains by addressing critical issues of interdependence, resilience and the emergence of new sectors.</span>
                                </li>
                                <li class="card-list-item">
                                    
                                    <span class="list-text">The EU faces the corresponding challenges posed by the geopolitical situation and competitive markets, while the development of the needed skills and the relocation of activities offer opportunities.</span>
                                </li>
                                <li class="card-list-item">
                                    
                                    <span class="list-text">A new logic of globalisation and the shifting geopolitical landscape bring changes to multilateralism, with corresponding effects on international trade and the composition of global value chains. Fostering international partnerships and sustainable development in a decolonised context can pave the way for global sustainability transitions.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        
        
            <!-- Featured section -->
            <section class="featured-section less-margin" id="methodology">
                <header class="section-header">
                    <h3>Building Capacity to Act of EU Actors in Sustainability Transitions</h3>
                </header>
                <div class="section-content">
                    <article class="content-block">
                        <figure class="image-container">
                            <img src="/assets/images/shaping_icon.png" loading="lazy" width="94.46808624267578" height="80" alt="Shaping" />
                        </figure>
                        <div class="text-content">
                            <h4 class="heading">Shaping</h4>
                            <p class="subheading">Change through systemic policy mixes</p>
                            <ul class="list">
                                <li class="list-item">Reformulating the social contract</li>
                                <li class="list-item">Renewing democracy</li>
                                <li class="list-item">Accelerating the development of sustainability competences</li>
                                <li class="list-item">Rethinking the mix of policy instruments</li>
                                <li class="list-item">Exiting unsustainable production and consumption</li>
                            </ul>
                        </div>
                    </article>
                    <article class="content-block">
                        <figure class="image-container">
                            <img src="/assets/images/navigating_icon.png" loading="lazy" width="94.46808624267578" height="80" alt="Navigating" />
                        </figure>
                        <div class="text-content">
                            <h4 class="heading">Navigating</h4>
                            <p class="subheading">Uncertainty and complexity</p>
                            <ul class="list">
                                <li class="list-item">Forming a strategy for complexity in geopolitics and multilateralism</li>
                                <li class="list-item">Building new capacity to act</li>
                                <li class="list-item">Managing the uncertainties of new technologies</li>
                                <li class="list-item">Steering change in global markets towards resilience</li>
                                <li class="list-item">Reducing dependencies through diversification</li>
                            </ul>
                        </div>
                    </article>
                    <article class="content-block">
                        <figure class="image-container">
                            <img src="/assets/images/orchestrating_icon.png" loading="lazy" width="94.46808624267578" height="80" alt="Orchestrating" />
                        </figure>
                        <div class="text-content">
                            <h4 class="heading">Orchestrating</h4>
                            <p class="subheading">Processes and relations</p>
                            <ul class="list">
                                <li class="list-item">Enabling portfolios of systemic interventions</li>
                                <li class="list-item">Removing barriers for collaboration between public and private sectors</li>
                                <li class="list-item">Strengthening the crucial role of regions and cities</li>
                                <li class="list-item">Supporting sustainable lifestyles</li>
                                <li class="list-item">Discouraging unsustainable consumption</li>
                            </ul>
                        </div>
                    </article>
                </div>
            </section>


            <!-- Highlight banner -->
            <section class="highlight-banner less-margin">
                <div class="jump-to-section">
                    <button class="icon-only" disabled>
                        <img
                            src="/assets/svg/star.svg"
                            loading="lazy"
                            width="24"
                            height="24"
                            alt="Share Icon"
                        />
                    </button>
                    <div class="jump-to-content">
                        <p class="jump-to-text">
                            <span>To learn more about the </span>
                            <span class="highlight">Agency of EU actors</span>
                            <span> in sustainability transitions</span>
                        </p>
                        <a href="#" target="_blank" class="jump-to-link button" @click.prevent="scrollTo('introduction-and-framing')">
                            <span class="link-text">Jump to this section</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M9.70703 16.9496L15.414 11.2426L9.70703 5.53564L8.29303 6.94964L12.586 11.2426L8.29303 15.5356L9.70703 16.9496Z" fill="#0E47CB"/></svg>
                        </a>
                    </div>
                </div>
            </section>

            
            <!-- Text block -->
            <section class="text-container">
                <div class="content-row">
                    <div class="content-column">
                        <article class="text-block">
                            <p>
                                The foresight study highlights the crucial need for coherent action across the EU to address these areas of intervention effectively. By strengthening the capacity to act (agency) and promoting collaboration throughout all parts of the EU, the challenges of achieving sustainability can be overcome. This involves harnessing engagement at all levels of governance, fostering public-private partnerships and collaboration to tackle the wide-ranging challenges linked to sustainability, as well as including and engaging citizens in decision-making processes. Through collective action, the EU can shape framework conditions, navigate uncertainty and geopolitical change, and forge strategic collaborations to drive sustainability transitions. These strategies can help to build new agency over time, through reinforcing interventions and by addressing external events decisively.
                            </p>
                        </article>
                        <article class="text-block">
                            <p>
                                This report is the result of an open, year-long inclusive and collaborative foresight process to gather valuable insights involving experts, policymakers, and social partners. Four foresight scenarios were developed to better envision what a climate-neutral European Union could look like in 2050; as well as four related pathways which could allow the EU to reach these possible sustainable futures. They are the result of a co-creation process based on planning backwards from the scenarios. A comparative analysis across the four pathways towards sustainability led to the identification of strategic areas of intervention, which are summarised above. They represent the key areas of change required in the coming decades for sustainability transitions.
                            </p>
                        </article>
                        <article class="text-block">
                            <p>
                                The outputs of this foresight study can serve as valuable resources for policymakers and practitioners engaging in sustainability transitions elsewhere. By using the actionable findings and recommendations, future exercises and initiatives can be developed to help drive sustainable change within the EU, at multiple levels of governance.
                            </p>
                        </article>
                    </div>
                    <figure class="image-container">
                        <img
                            src="/assets/images/example_3.jpg"
                            loading="lazy"
                            width="367"
                            height="920"
                            alt="Foresight Study Image"
                        />
                    </figure>
                </div>
            </section>

        
            <!-- Summary container -->
            <section class="summary-container">
                <h3>Summary of EU 2050 Scenarios and Transition Pathways</h3>
                <div class="scenario-table">
                    <div class="scenario-card" id="eco-states">
                        <div class="scenario-header">
                            <div class="titles-wrapped">
                                <h4>Eco-states</h4>
                                <p class="scenario-description">
                                    National governments and the EU drive change – motivated by climate change impacts
                                </p>
                            </div>
                            <img
                                src="/assets/images/eco_state.png"
                                loading="lazy"
                                width="133"
                                height="132"
                                alt="Eco-states"
                                class="scenario-image"
                            />
                        </div>
                        <div class="contextual-factors">
                            <h4 class="contextual-heading">Contextual Factors</h4>
                            <ul class="contextual-list">
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/social_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Social"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Social:</strong> Trust in governments, focus on the common good, equality and social conformity
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/technological_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Technological"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Technological:</strong> Public funding for top-down innovation, high-tech digital tools, transparency and monitoring
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/economic_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Economic"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Economic:</strong> Highly regulated social market economy, new taxes, strong public budgets
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/environmental_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Environmental"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Environmental:</strong> Nature & climate highly valued to serve society, focus on food security
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/political_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Political"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Political:</strong> Strong governments, top-down decision making, the EU empowered in a few new areas (e.g. energy, taxes)
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/geopolitical_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Geopolitical"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Geopolitical:</strong> Persisting world order with international organisations
                                    </div>
                                </li>
                            </ul>
                            <div class="transition-pathways">
                                <h4 class="pathways-heading">Transition Pathways - Key Patterns of Change</h4>
                                <ul class="pathways-list">
                                    <li class="pathways-item">Using legislation, education and public engagement to turn consumers into ecology-conscious citizens</li>
                                    <li class="pathways-item">Substantial fiscal reforms for sustainability</li>
                                    <li class="pathways-item">State-led technology development for sustainability</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="scenario-card" id="greening-through-crisis">
                        <div class="scenario-header">
                            <div class="titles-wrapped">
                                <h4>Greening through Crisis</h4>
                                <p class="scenario-description">
                                    Crisis-response and the EU drive change – motivated by geopolitical instability and climate change impacts
                                </p>
                            </div>
                            <img
                                src="/assets/images/greening.png"
                                loading="lazy"
                                width="125"
                                height="132"
                                alt="Greening through Crisis"
                                class="scenario-image"
                            />
                        </div>
                        <div class="contextual-factors">
                            <h4 class="contextual-heading">Contextual Factors</h4>
                            <ul class="contextual-list">
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/social_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Social"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Social:</strong> Trust in government and the EU, focus on stability and security
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/technological_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Technological"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Technological:</strong> Public-private partnerships for innovation, EU-based and EU-made digital tools and infrastructure
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/economic_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Economic"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Economic:</strong> Constrained liberal economy, re-shoring & friend-shoring, industry 5.0
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/environmental_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Environmental"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Environmental:</strong> Nature & climate valued for capacity to serve society, focus on mitigating risks
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/political_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Political"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Political:</strong> Strong Member States, top-down decision making, EU empowered in a number of areas (e.g. defence, energy)
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/geopolitical_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Geopolitical"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Geopolitical:</strong> The world is more hostile and fragmented, multilateralism significantly reduced
                                    </div>
                                </li>
                            </ul>
                            <div class="transition-pathways">
                                <h4 class="pathways-heading">Transition Pathways - Key Patterns of Change</h4>
                                <ul class="pathways-list">
                                    <li class="pathways-item">An emerging consensus on Green Strategic Autonomy</li>
                                    <li class="pathways-item">Shifts in governance and democracy towards more EU competences and important local governance</li>
                                    <li class="pathways-item">A profound reconfiguration of global supply and value chains</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="scenario-card" id="green-business-boom">
                        <div class="scenario-header">
                            <div class="titles-wrapped">
                                <h4>Green Business Boom</h4>
                                <p class="scenario-description">
                                    Incentivised (global) markets drive change – motivated by high costs, scarcity, and unreliable supply chains
                                </p>
                            </div>
                            <img
                                src="/assets/images/boom.png"
                                loading="lazy"
                                width="145"
                                height="110"
                                alt="Green Business Boom"
                                class="scenario-image"
                            />
                        </div>
                        <div class="contextual-factors">
                            <h4 class="contextual-heading">Contextual Factors</h4>
                            <ul class="contextual-list">
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/social_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Social"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Social:</strong> Individualistic, hardworking, high trust in innovation & tech, inequalities persist
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/technological_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Technological"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Technological:</strong> Thriving innovation, private funding, biotech, high-tech digital tools
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/economic_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Economic"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Economic:</strong> Dynamic circular economy, green taxes, low regulation
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/environmental_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Environmental"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Environmental:</strong> Nature respected as a limited resource
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/political_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Political"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Political:</strong> Pragmatic EU and Member States, strong influence of corporate interests, esp. large EU-wide companies
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/geopolitical_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Geopolitical"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Geopolitical:</strong> The EU makes strategic alliances for resources and coordinates actions for big EU corporations
                                    </div>
                                </li>
                            </ul>
                            <div class="transition-pathways">
                                <h4 class="pathways-heading">Transition Pathways - Key Patterns of Change</h4>
                                <ul class="pathways-list">
                                    <li class="pathways-item">Liberal government action spurred a technology-led green systemic reconfiguration</li>
                                    <li class="pathways-item">Strong cooperation between public and private actors boosts a deep transition</li>
                                    <li class="pathways-item">New models for enabling social mobility</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="scenario-card" id="glocal-eco-world">
                        <div class="scenario-header">
                            <div class="titles-wrapped">
                                <h4>Glocal Eco-world</h4>
                                <p class="scenario-description">
                                    People and behaviour drive change – motivated by government inaction
                                </p>
                            </div>
                            <img
                                src="/assets/images/world.png"
                                loading="lazy"
                                width="126"
                                height="124"
                                alt="Glocal Eco-world"
                                class="scenario-image"
                            />
                        </div>
                        <div class="contextual-factors">
                            <h4 class="contextual-heading">Contextual Factors</h4>
                            <ul class="contextual-list">
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/social_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Social"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Social:</strong> Communitarian, highly participative and egalitarian, focus on wellbeing and sufficiency
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/technological_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Technological"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Technological:</strong> Bottom-up innovation focused on local needs, lack of public funds and infrastructure
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/economic_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Economic"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Economic:</strong> Local collaborative economy, service-based business models and social entrepreneurship
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/environmental_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Environmental"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Environmental:</strong> Nature highly valued for human well-being and given rights
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/political_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Political"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Political:</strong> Member States lost competences to the local level for resilience and the EU level for security
                                    </div>
                                </li>
                                <li class="contextual-item">
                                    <img
                                        src="/assets/images/geopolitical_icon.png"
                                        loading="lazy"
                                        width="40"
                                        height="40"
                                        alt="Geopolitical"
                                        class="contextual-icon"
                                    />
                                    <div class="contextual-text">
                                        <strong>Geopolitical:</strong> Fragmented world with a relatively weaker, but locally resilient EU
                                    </div>
                                </li>
                            </ul>
                            <div class="transition-pathways">
                                <h4 class="pathways-heading">Transition Pathways - Key Patterns of Change</h4>
                                <ul class="pathways-list">
                                    <li class="pathways-item">People started taking things into their own hands, leading to a new social contract</li>
                                    <li class="pathways-item">Economic shift towards sufficiency, a first step towards strategic autonomy</li>
                                    <li class="pathways-item">Towards a redesigned EU and new forms of local democracy</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    </section>
</template>

<script>

export default {
    name: "HomeView",
    props: {
        msg: String,
    },
    methods: {
        scrollTo(id) {
            const targetElement = document.getElementById(id);

            if (targetElement) {
                const nodePosition = targetElement.getBoundingClientRect().top + window.scrollY;
                
                window.scroll({
                    top: nodePosition - 150,
                    behavior: 'smooth'
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/views/home.scss";
</style>
